/*
	FORMS - THIS STYLESHEETS OVERWRITES THE BASICS AND DELIVERS
	SPECIALTIES FOR VARIOUS MODULES
======================================================================== */

/* special form items that are not basic
======================================================================== */

.nm-configuration-started #nm-id-form-item-Item-VehicleBrand-VehicleModel,
.nm-configuration-started #nm-id-form-item-item_desiredcarline,
.nm-configuration-started #nm-id-form-item-item_desiredcarlinegroup,
#nm-id-form-item-Item-vvega-enquiryengineinfo,
#nm-id-form-item-Item-UserAddressHomeCareOf .nm-cp {
	display: none;
}

#nm-id-form-field-QualificationDataCustomerInterest2 label,
#nm-id-form-field-QualificationDataCustomerInterest1 label,
.nm-configuration-started #nm-id-form-item-Item-vvega-enquiryengineinfo {
	display: block;
}

#nm-id-form-field-UserDataUseAgreementPostal a {
	text-decoration: underline;
}

#nm-id-form-field-vvega-enquiryengineinfo:disabled {
	min-width: 50%;
	width: auto;
}

#nm-id-form-item-Item-vvega-enquiryengineinfo:disabled {
	width: 62%;
}

/* individual input length */
.nm-form-item-multiple #UserAddressHomeZip,
.nm-form-item-multiple #UserAddressHomeHouseNumber {
	width: 70px;
}

.nm-form-item-multiple #UserAddressHomeZip,
.nm-form-item-multiple #UserAddressHomeStreet {
	margin-right: 10px;
}

.nm-form-item-multiple #UserAddressHomeStreet,
.nm-form-item-multiple #UserAddressHomeCity {
	width: 180px;
}

#nm-id-form-field-UserAddressHomeStreet:disabled,
#nm-id-form-field-UserAddressHomeZip:disabled,
.nm-form-item-multiple #UserAddressHomeStreet:disabled,
.nm-form-item-multiple #UserAddressHomeCity:disabled {
	width: auto;
}

#nm-id-form-field-UserBirthDay,
#nm-id-form-field-UserBirthYear {
	width: 75px;
}

#nm-id-form-field-UserBirthMonth {
	width: 90px;
}

/* individual positioning */
#nm-id-form-field-UserDataUseAgreementEmail,
#nm-id-form-item-Item_UserDataUseAgreementPhone,
#nm-id-form-field-UserDataUseAgreementPhone {
	padding-left: 40px;
}

#nm-id-form-item-Item-VehicleBrand-VehicleModel > .nm-label {
	display: block;
	margin-bottom: 10px;
	width: 100%;
}

/* form modules
===========================================================================

/* login, audicode */
.nm-login-needed,
.nm-logged-in .nm-login-form,
.nm-logged-in .nm-hide-after-login {
	display: none;
}

.nm-logged-in .nm-login-needed {
	display: block;
}

/* simpleforms */
.nm-3col .nm-50col {
	box-sizing: border-box;
	padding: 0 0 0 50px;
	width: 325px;
}

.nm-3col .nm-50col:first-child {
	border-right: 1px solid #d0d3d4;
	padding: 0 50px 0 0;
}

.nm-simpleform {
	margin-bottom: 75px;
	position: relative;
}

/* audicode */
.nm-label-ac {
	background-color: #e7e9ea;
	box-sizing: border-box;
	font: normal 36px/1 AudiTypeExtended, Verdana, sans-serif;
	height: 65px;
	padding: 15px 0;
	text-align: center;
	width: 275px;
}

.nm-simpleform .nm-2col .nm-hl-pu {
	font: normal 14px/1.429 AudiTypeExtended, Verdana, sans-serif;
	font-weight: var(--font-weight-bold);
	margin-bottom: 13px;
}

.nm-simpleform .nm-2col .nm-c1 {
	font: normal 12px/20px Verdana, sans-serif;
	margin-bottom: 20px;
}

.nm-simpleform h2 {
	margin-bottom: 10px;
}

.nm-simpleform p,
.nm-form p.nm-el-pg-04 {
	margin-bottom: 22px;
}

.nm-simpleform .nm-error,
.nm-simpleform .nm-form-standard-error {
	display: none;
}

/* JS classes */
.nm-j-simpleform-error .nm-form-standard-error,
.nm-j-simpleform-error .nm-error {
	display: block;
}

.nm-j-simpleform-loading::after {
	background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGElEQVQYlWP89+//fgYiABMxikYVUk8hAADpA869ASoHAAAAAElFTkSuQmCC");
	content: "";
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.nm-j-simpleform .nm-success,
.nm-j-simpleform .nm-error,
.nm-j-audicode-error {
	display: none;
}

.nm-j-simpleform-success .nm-success,
.nm-j-simpleform-error .nm-error,
.nm-j-audicode-failure .nm-j-audicode-error {
	display: block;
}

.nm-j-audicode-failure .nm-h3,
.nm-j-audicode-failure .nm-label-ac,
.nm-j-audicode-failure .nm-remarks {
	display: none !important;
}

.nm-j-audicode-loading .nm-label-ac {
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

/* end JS classes */

/* two input fields and a button in a row */
.nm-simpleform input {
	border: 1px solid #d0d3d4;
	color: #6d7579;
	font-family: Verdana, sans-serif;
	font-size: 11px;
	height: 40px;
	line-height: 1;
	padding: 0 15px;
	width: 198px;
}

.nm-simpleform.nm-j-simpleform-error input,
.nm-simpleform.nm-j-audicode-failure .nm-j-audicode-error input {
	border: 1px solid #c03;
}

.nm-simpleform button.nm-el-btn-04 {
	margin-left: -5px;
	padding-bottom: 12px;
	vertical-align: top;
}

.nm-one-line-form input {
	width: 250px;
}

.nm-simpleform .nm-one-line-form input {
	width: -webkit-calc(50% - 54px);
	width: calc(50% - 54px);
}

.nm-one-line-form input:first-child {
	margin-right: -5px;
}

.nm-simpleform .nm-remarks,
.nm-simpleform .nm-login-help-list {
	margin-top: 14px;
}

.nm-simpleform .nm-remarks > span {
	display: inline;
}

.nm-simpleform.nm-audicode-display .nm-remarks {
	white-space: nowrap;
}

.nm-simpleform.nm-audicode-display h2 + p {
	margin-bottom: 12px;
}

.nm-simpleform.nm-audicode-form h2 + p {
	margin-bottom: 12px;
}

.nm-simpleform.nm-audicode-form input {
	box-sizing: border-box;
	max-width: 230px;
	width: -webkit-calc(100% - 45px);
	width: calc(100% - 45px);
}

.nm-simpleform.nm-login-form p + form,
.nm-simpleform.nm-login-form .nm-success + form {
	margin-top: 40px;
}

.nm-simpleform .nm-login-help-list li {
	display: inline-block;
	margin-right: 10px;
}

.nm-carstore-save .nm-simpleform.nm-login-form {
	margin: 45px 0;
}

.nm-simpleform.nm-audicode-display {
	margin-bottom: 5px;
}

/* tdrdealerreq custom forms */
.nm-radio.nm-form-custom-tdrdealerreq-step2-item1 {
	clear: none;
	float: left;
	width: 62.5%;
}

.nm-form-custom-tdrdealerreq-step2-item1 .nm-select {
	margin-left: 30px;
}

#nm-id-custom-tdrdealerreq-configuration-info {
	margin: 30px 0 30px 30px;
}

#nm-id-custom-tdrdealerreq-configuration-info img {
	float: left;
	height: 102px;
	width: 250px;
}

.nm-form-configuration-info {
	margin-left: 270px;
	width: 200px;
}

.nm-form-custom-tdrdealerreq-step2-item1-radio2 {
	clear: both;
}

@media screen and (max-width: 859px) {
	.nm-state-is-responsive .nm-label,
	.nm-state-is-responsive .nm-select label,
	.nm-state-is-responsive .nm-input label,
	.nm-state-is-responsive .nm-form-label,
	.nm-state-is-responsive .nm-form-label-required {
		width: 100%;
	}

	#fdproxy select:not(.audi-footer__language-switch-select),
	.nm-state-is-responsive .nm-input p,
	.nm-state-is-responsive .nm-input input,
	.nm-state-is-responsive .nm-input textarea,
	.nm-state-is-responsive .legacy select:not(.audi-footer__language-switch-select) {
		width: 100%;
	}

	.nm-state-is-responsive .nm-form-item .nm-error {
		padding-right: 0;
		position: relative;
		width: -webkit-calc(100% - 27px);
		width: calc(100% - 27px);
	}

	.nm-state-is-responsive .nm-radio .nm-input-wrap {
		margin-left: 8px;
		width: -webkit-calc(100% - 8px);
		width: calc(100% - 8px);
	}

	.nm-state-is-responsive .nm-form-item-multiple .nm-input {
		float: none;
	}

	.nm-state-is-responsive .nm-form-item-multiple #UserAddressHomeStreet,
	.nm-state-is-responsive .nm-form-item-multiple #UserAddressHomeCity,
	.nm-state-is-responsive .nm-form-item-multiple #UserAddressHomeZip,
	.nm-state-is-responsive .nm-form-item-multiple #UserAddressHomeHouseNumber,
	.nm-state-is-responsive #nm-id-form-field-UserBirthDay,
	.nm-state-is-responsive #nm-id-form-field-UserBirthMonth,
	.nm-state-is-responsive #nm-id-form-field-UserBirthYear {
		width: 100%;
	}

	.nm-state-is-responsive .nm-radio label {
		display: block;
		float: none;
		width: 100%;
	}

	.nm-state-is-responsive #nm-id-form-field-UserSalutation.nm-radio label {
		display: inline;
	}

	.nm-state-is-responsive .nm-form-item-datetime select {
		background-position: 95% center;
	}

	.nm-state-is-responsive .nm-label,
	.nm-state-is-responsive .nm-form-label,
	.nm-state-is-responsive .nm-form-label-required {
		float: none;
	}

	.nm-state-is-responsive .nm-form-item .nm-info {
		left: auto;
		right: 0;
	}

	.nm-state-is-responsive .nm-legend.nm-h3 {
		padding-right: 30px;
	}

	.nm-state-is-responsive .nm-form-item .nm-radio .nm-info {
		left: auto;
		right: 0;
	}

	.nm-state-is-responsive .nm-form-item .nm-info:hover .nm-tooltip,
	#nm-id-form-item-fs-products-item .nm-form-item .nm-info:hover .nm-tooltip,
	.nm-state-is-responsive #nm-id-form-field-fs-products-list .nm-info:hover .nm-tooltip {
		left: auto;
		max-width: 280px;
		right: 0;
	}
}

@media (max-width: 749px) {
	.nm-state-is-responsive .nm-layer-dialogue .nm-beta .nm-simpleform {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 699px) {
	.nm-state-is-responsive .nm-form-configuration-info {
		margin-left: 0;
	}

	.nm-state-is-responsive #nm-id-custom-tdrdealerreq-configuration-info img {
		margin-bottom: 15px;
	}
}

@media (max-width: 479px) {
	.nm-state-is-responsive .nm-simpleform .nm-one-line-form input {
		box-sizing: border-box;
		margin-bottom: 10px;
		width: 100%;
	}

	.nm-state-is-responsive .nm-simpleform.nm-login-form button.nm-el-btn-04 {
		color: #fff;
		float: right;
		margin-left: 0;
		max-width: none;
		padding-top: 12px;
		text-indent: 0;
	}

	.nm-state-is-responsive .nm-simpleform .nm-login-help-list {
		margin-top: 30px;
	}

	.nm-state-is-responsive .nm-simpleform .nm-login-help-list li {
		display: block;
		margin-bottom: 20px;
	}

	.nm-carstore-save .nm-simpleform.nm-login-form {
		margin-top: 0;
	}
}
